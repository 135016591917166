/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-html-link-for-pages */
import { FC, useEffect, useState, useRef, createRef, useMemo } from 'react';
import Head from 'next/head';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import cx from 'classnames';
import UIButton from 'components/Others/UIButton';
import axios from 'axios';
import router, { useRouter } from 'next/router';
import Swal from 'sweetalert2';
import CalendarCancelAlt from 'components/Icons/CalendarCancelAlt';
import withReactContent from 'sweetalert2-react-content';
import { bookingEvents } from 'functions/bookingEvents';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedStore, setSelectedService, setSelectedProvider, setBookingResponse, setBookingInfo, setSelectedTime } from 'redux/features/booking/bookingSlice';
import StoreSelection from 'components/Booking/StoreSelection';
import TimeSelection from 'components/Booking/TimeSelection';
import ConfirmSelection from 'components/Booking/ConfirmSelection';
import ProviderSelection from 'components/Booking/ProviderSelection';
import ServiceSelection from 'components/Booking/ServiceSelection';
import SuccessBooking from 'components/Booking/SuccessBooking';
import {stringToSlug} from 'functions/slug';
import {parse} from "date-fns";
import CategorySeoText from 'components/Others/CategorySeoText';
import LazyHydrate from 'react-lazy-hydration';
import { BookingPageProps } from 'constants/dataTypes';
import { getSeoBlockContent, getSeoMetaTags } from 'utils/common.utils';
import GlobalSeoMetaTags from 'components/GlobalSeoMetaTags/GlobalSeoMetaTags';
//import * as Sentry from '@sentry/nextjs';


const MySwal = withReactContent(Swal)

const BookingMenuItem = (props: any) => {
  const bookingMenuMap:any = {
    1: '#store',
    2: '#service',
    3: '#provider',
    4: '#date',
    5: '#customer',
    6: '#confirm'
  }
  return (<li onClick={() => {
    if(props.activePage > props.pos){
      props.setActivePage(props.pos)
      router.push(bookingMenuMap[props.pos])
    }
    /*props.setActivePage(props.pos)*/ }} className={cx('transition-all select-none relative rounded-full text-sm font-medium pl-4 first-of-type:ml-1 pr-4 ml-8 mr-8 h-9 whitespace-nowrap',
    'lgx:pr-2 lgx:mr-3 lgx:before:-left-3.5 lgx:before:max-w-[10px] lgx:before:h-0.5  lgx:before:top-4 first-of-type:before:hidden before:transition-all before:absolute before:w-full before:max-w-[25px] before:bg-brand-grey500 before:h-1 before:top-3 before:mt-[1px] before:rounded-r-lg before:-left-9 before:content-[" "] ',
    'lgx:pl-2 lgx:ml-3 lgx:after:ml-3 lgx:after:max-w-[10px] lgx:after:h-0.5 lgx:after:top-4 last-of-type:after:hidden m-0 after:transition-all after:absolute after:w-full after:max-w-[25px] after:bg-brand-grey500 after:h-1 after:top-3 after:mt-[1px] after:rounded-l-lg after:ml-6 after:content-[" "] ',
    {
      'mt-[-2px] ml-8 mr-8 bg-white bg-opacity-90 border-2 text-brand-orange  leading-7   ': props.activePage === props.pos,
      'mt-[-2px] bg-brand-orange border-2 border-brand-orange text-white leading-7  cursor-pointer': props.activePage > props.pos,
      'border border-brand-grey450 text-brand-grey450 leading-8 ': props.activePage < props.pos
    })}>{props.title}</li>);
}

const NoAppointments = ({ setActivePage }: any) => {
  const dispatch = useDispatch()
  return (<div className='flex flex-col items-center justify-center h-full bg-brand-grey650 p-20'>
    <CalendarCancelAlt className='mx-auto'/>
    <div className='text-brand-grey400 text-4xl font-medium mb-4'>There are no appointments yet.</div>
    <div className='text-brand-grey400 text-xl font-medium mb-4'>Press the “Book Now” button to make an appoinment.</div>
    <div className='flex mt-2'><UIButton onClick={() => { 
      //setCurrentState(() => Object.assign(currentState, { 'selectedService': null, 'selectedProvider': null, 'selectedStore': null,'bookingInfo': null, 'bookingResponse': null }));
      dispatch(setSelectedProvider(null));
      dispatch(setSelectedService(null));
      dispatch(setSelectedStore(null));
      dispatch(setBookingInfo(null))
      dispatch(setBookingResponse(null));
      dispatch(setSelectedTime(null));
      setActivePage(() => 1) 
    }}>Book Now</UIButton></div>
  </div>);
}


const Booking: FC<BookingPageProps> = ({
  title,
  description,
  h1,
  stores,
  services,
  providers,
  breadcrumbs,
  seoBlock,
  seoMetaTags
}) => {
  const router = useRouter();
  
  const [activePage, setActivePage] = useState(1);
  const scrollContainer = useRef<any | HTMLElement>(null)

  const executeScroll = () => scrollContainer?.current?.scrollIntoView();  
  //const [providers, setProviders] = useState<any>([]);
  const [calendarData, setCalendarData] = useState<any>([]);

  const [additionalFields, setAdditionalFields] = useState<any>([]);
  const [updateCustomerFormKey, setUpdateCustomerFormKey] = useState<any>(0);
  const onlyStores: any = useMemo(() => Object.values(stores).reduce((item: any, acc: any) => [...item, ...acc]), [stores])
  const indicator = useRef<any>(null);
  const [successBookingRenderKey, setSuccessBookingRenderKey] = useState(0);

  const dispatch = useDispatch();
  
  
const selectedProvider = useSelector((state:any) => state.bookingEvent.selectedProvider)
//const bookingInfo = useSelector((state:any) => state.bookingEvent.bookingInfo)
const selectedService = useSelector((state:any) => state.bookingEvent.selectedService)
const selectedStore = useSelector((state:any) => state.bookingEvent.selectedStore)
//const bookingResponse = useSelector((state:any) => state.bookingEvent.bookingResponse)
  
  // const {width} = useWindowSize();

  const bookingMenuMap = {
    1: '#store',
    2: '#service',
    3: '#provider',
    4: '#date',
    5: '#customer',
    6: '#confirm'
  }
  /*const hasRegion = router.query.region ? '?region='+router.query.region : '';*/


  useEffect(() => {
    if (!selectedService) return;
    axios.get(`${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/additional-fields?service_id=${selectedService.id}`, {
      headers: {
        'region': router.locale as string
      }
    }).then((res) => {
      setAdditionalFields(() => res.data.data)
      setUpdateCustomerFormKey(() => updateCustomerFormKey + 1)
    })

  }, [selectedService])

  useEffect(() => {
    executeScroll();
    if (indicator.current) {
      indicator.current.scrollLeft = (indicator.current.scrollWidth / 5) * (activePage - 1)-15;
    }
    if(activePage==1){
      bookingEvents.Booking1_ViewStore();
    }

    if(activePage==6){
      //bookingEvents.Booking5_Success(selectedProvider, selectedService, selectedStore, bookingInfo, bookingResponse, additionalFields);
    }
  
    window.addEventListener("hashchange", () => {
      if(window.location.hash === bookingMenuMap[3]) {
        if(!selectedProvider?.id || !selectedStore?.id) return setActivePage(() => 1);
        setActivePage(() => 3);
        //router.push('#provider')
      } else if(window.location.hash === bookingMenuMap[2]) {
        if(!selectedStore?.id) return setActivePage(() => 1);
        setActivePage(() => 2);
        //router.push('#service')
      } else if(window.location.hash === bookingMenuMap[1]) {
        
        setActivePage(() => 1);
        //router.push('#store')
      } else if(window.location.hash === bookingMenuMap[4]) {
        if(!selectedStore?.id || !selectedProvider?.id || !selectedService?.id) return setActivePage(() => 1);
        setActivePage(() => 4);
        //router.push('#date')
      } else if(window.location.hash === bookingMenuMap[5]) {
        if(!selectedStore?.id || !selectedProvider?.id || !selectedService?.id) return setActivePage(() => 1);
        setActivePage(() => 5);
        //router.push('#customer')
      } else if(window.location.hash === bookingMenuMap[6]){
        if(!router.query?.c && !selectedStore?.id || !selectedProvider?.id || !selectedService?.id) return setActivePage(() => 1);
        setActivePage(() => 6);
        //router.push('#confirm')
      }else {
        setActivePage(() => 1);
      }
    });

  }, [activePage])
  


  const handleQuery = async (code:any) => {
      setActivePage(() => 6);
      //setSuccessBookingRenderKey(() => successBookingRenderKey+1);
      axios.get(`${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/get-booking?code=${code}`, {
        headers: {
          'region': router.locale as string
        }
        }).then(async (res) => {
          const provider: any = new Promise((resolve, reject) => { 
            const x = providers.find((provider: any) => provider.id == res?.data?.unit_id);
            resolve(x)
          })
          const service: any = new Promise((resolve, reject) => { 
            const x = services.find((service: any) => service.name == res?.data?.event_name);
            resolve(x)
          })
          const store: any = new Promise((resolve, reject) => { 
            const x = onlyStores.find((store:any) => store.providers.includes(res?.data?.unit_id));
            resolve(x)
          })

          Promise.all([provider, service, store]).then((r1:any) => {

            
            dispatch(setSelectedProvider(r1[0]))
            dispatch(setSelectedService(r1[1]))
            dispatch(setSelectedStore(r1[2]))
            dispatch(setBookingInfo({
              "client_name": res?.data?.client_name,
              "client_email": res?.data?.client_email,
              "client_phone": res?.data?.client_phone,
              "location_id": r1[2]?.id,
              "service_id": res?.data?.event_id,
              "provider_id": res?.data?.unit_id,
              "additional_fields": res?.data?.additional_fields.reduce((acc:any, item:any) => {
                acc[item.field_name] = item.value;
                return acc;
              }, {})
            }))
            dispatch(setBookingResponse({
              "code": res?.data?.code,
              "client": res?.data?.client_name,
              "provider": res?.data?.unit_name,
              "provider_id": res?.data?.unit_id,
              "service": res?.data?.event,
              "service_id": res?.data?.event_id,
              "booking_duration": res?.data?.event_duration,
            }));

            dispatch(setSelectedTime(new Date(parse(res?.data?.start_date_time, "yyyy-MM-dd HH:mm:ss", new Date()))))

            if (router?.query?.reschedule == '' && res?.data?.is_confirmed != "0") {
              if(!r1[1]?.id) {
                setActivePage(() => 2);
              }else{
                setActivePage(() => 4)
              }
            }
          })
          
          if (router?.query?.cancel == ''  && res?.data?.is_confirmed != "0") {
            MySwal.fire({
              title: "Cancel Booking",
              text: "Please confirm cancellation of this appointment.",
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Back',
              reverseButtons: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn bg-brand-black100 text-white m-3 pl-5 pr-5 text-base p-2.5 rounded-md ml-2',
                cancelButton: 'btn m-3 rounded-md border border-brand-grey400 p-2.5 pl-5 pr-5'
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const requestBody: any = { 'code': res?.data?.code, 'region': router.locale }
                axios.post(`${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/cancel-booking`, requestBody, {
                  headers: {
                    'region': router.locale as string
                  }
                }).then((res) => {
                  console.log("canceled booking ?")
                  //setCurrentState(() => Object.assign(currentState, { 'selectedService': null, 'selectedProvider': null, 'selectedStore': null, 'bookingInfo': null, 'bookingResponse': null }));
                  dispatch(setSelectedProvider(null));
                  dispatch(setSelectedService(null));
                  dispatch(setSelectedStore(null));
                  dispatch(setBookingInfo(null))
                  dispatch(setBookingResponse(null));
                  dispatch(setSelectedTime(null));

                  MySwal.fire({
                    text: "Your booking has successfully cancelled.",
                    showCancelButton: false,
                    confirmButtonText: 'Close',
                    reverseButtons: true,
                    buttonsStyling: false,
                    customClass: {
                      confirmButton: 'btn bg-brand-black100 text-white m-3 pl-5 pr-5 text-base p-2.5 rounded-md ml-2',
                      cancelButton: 'btn m-3 rounded-md border border-brand-grey400 p-2.5 pl-5 pr-5'
                    },
                  })

                  setActivePage(() => 7)


                })

              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
                /*Swal.fire(
                  'Cancelled',
                  'Your imaginary file is safe :)',
                  'error'
                )*/
              }
            })
          }
          

          if (res?.data?.is_confirmed == "0") {
            setActivePage(() => 7);
          }

        }).catch((err) => {
          if(err.request.status == 400) setActivePage(() => 7)
        })

  }



  const handleSelectedStoreFromQuery =  (storeSlug:any) => {
    const store = onlyStores.find((store:any) => stringToSlug(store?.address2) == storeSlug);
    if(store){
      dispatch(setSelectedStore(store));
      setActivePage(() => 2)
    } 
  }
  
  useEffect(() => {
    const code = router?.query?.c;
    const store = router?.query?.store;
    //const globalState = {};
    if(code){
      handleQuery(code);
      
    }

    if(store){
      handleSelectedStoreFromQuery(store);
    }
    
  },[router?.query?.c, router?.query?.store])

  useEffect(() => {
    if (router && router.locale !== 'au') {
      location.reload();
    }
  }, [router]);




  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="twitter:title" content={title} />
        <meta name="og:title" content={title} />
        <meta name="description" content="Looking for a Optometrist? All eye tests are bulk billed which means there will be no out-of-pocket expenses for Medicare card holders." />
        <meta property="og:description" content="Looking for a Optometrist? All eye tests are bulk billed which means there will be no out-of-pocket expenses for Medicare card holders." />
        <meta name="twitter:description" content="Looking for a Optometrist? All eye tests are bulk billed which means there will be no out-of-pocket expenses for Medicare card holders." />
      </Head>
      <div className="relative min-h-[130px] w-full bg-brand-grey650" ref={scrollContainer}>
        <div className="z-20 mx-auto left-0 right-0 w-full text-center items-center justify-center text-brand-black100 bg-opacity-20 max-w-2xl pb-10">
          <h1 className="p-4 text-center font-sans text-4xl font-bold pt-10">
            {h1}
          </h1>
          <div className={cx('block m-3', {
            'lgx:hidden':activePage > 1
          })}>{description}</div>
        </div>
      </div>
      <div className="mx-auto mt-[18px] max-w-[1224px] lg:px-4" >
        <div className="mb-[18px] lgx:ml-4" >
          <Breadcrumb data={breadcrumbs} />
        </div>
        {activePage <= 5 && <div className="text-center flex justify-center lgx:overflow-hidden">
          <ul className="flex relative items-center mt-2 overflow-y-scroll m-2 h-14" ref={indicator}>
            <BookingMenuItem title={"Location"} pos={1} activePage={activePage} setActivePage={setActivePage} />
            <BookingMenuItem title={"Appointment Type"} pos={2} activePage={activePage} setActivePage={setActivePage} />
            <BookingMenuItem title={"Provider"} pos={3} activePage={activePage} setActivePage={setActivePage} />
            <BookingMenuItem title={"Date & Time"} pos={4} activePage={activePage} setActivePage={setActivePage} />
            <BookingMenuItem title={"Patient Details"} pos={5} activePage={activePage} setActivePage={setActivePage} />
          </ul>
        </div>}
        <div className="mx-auto">
          {/*JSON.stringify(currentState)*/}
          {activePage == 1 && <StoreSelection stores={stores} setActivePage={setActivePage} />}
          {activePage == 2 && <ServiceSelection services={services} setActivePage={setActivePage} />}
          {activePage == 3 && <ProviderSelection providers={providers} setActivePage={setActivePage} />}
          {activePage == 4 && <TimeSelection setActivePage={setActivePage} calendarData={calendarData} providers={providers}/>} 
          {activePage == 5 && <ConfirmSelection key={updateCustomerFormKey} setActivePage={setActivePage} additionalFields={additionalFields} />}
          {activePage == 6 && <SuccessBooking setActivePage={setActivePage} key={successBookingRenderKey} />}
          {activePage == 7 && <NoAppointments setActivePage={setActivePage} />}
        </div>
        <div className="mx-auto px-4">
        <LazyHydrate whenVisible>
          <CategorySeoText seoBlock={seoBlock} />
        </LazyHydrate>
        </div>
      </div>
      <GlobalSeoMetaTags  seoMetaTags={seoMetaTags} />
    </div>
  );
};


// export const getStaticPaths = async (context:any) => {

// }

export const getStaticProps = async (context: any) => {
  const { locale } = context;
  //Sentry.setTag("tag", "booking");
  //console.log(locale);

 const BREADCRUMB_DATA = [
    {
      id: 1,
      href: '',
      text: 'Dresden Vision'
    },
    {
      id: 2,
      href: '',
      text: locale=='au'?'Book an optometrist':'Book an eye exam'
    }
  ]

  const bookingHeader = new Headers();
  bookingHeader.append('region', locale);
  const mainReq = await fetch(process.env.NEXT_PUBLIC_LARAVEL_URL + '/bookings/booking-data', { headers: bookingHeader });
  const mainData = await mainReq.json();
  //console.log(mainData)

  const descriptionLocaleMap:any = {
    'au':'All eye tests are bulk billed which means there will be no out-of-pocket expenses for Medicare card holders. If you need any assistance in making a booking, please call us at (02) 5300 3003 or send us an email at hello@dresden.vision.',
    'ca':'We direct bill most insurers in Canada. Make an appointment with our friendly, expert optometrists today. If you need any assistance in making a booking, please call us at (647) 424 4288 or send us an email at hello@ca.dresden.vision.',
    'nz':'Eye tests are bulk billed, so you will face no out-of-pocket expenses if you are listed on a valid Medicare card without optometry restrictions.',
    'international':'Eye tests are bulk billed, so you will face no out-of-pocket expenses if you are listed on a valid Medicare card without optometry restrictions.'
  }

  const seoBlock = await getSeoBlockContent('book-an-optometrist', undefined);

  const seoMetaTags = await getSeoMetaTags('/book-an-optometrist', locale);

  return {
    props: {
      title:
        'Book an Optometrist |  - Dresden',
      description:descriptionLocaleMap[locale],
      h1: 'Book an Optometrist',
      stores: mainData?.data.locations || [],
      services: mainData?.data.services,
      providers: mainData?.data.providers,
      breadcrumbs: BREADCRUMB_DATA,
      seoBlock,
        seoMetaTags
    },
    revalidate: 60*30,
  };
};

export default Booking;
